<!--
 * @Author: lzh
 * @Date: 2022-07-06 20:14:39
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-10 10:29:48
 * @Description: file content
-->
<template>
  <div class="company-introduction-box px-company-introduction-box">
    <div class="top-box">
      <img
        class="img"
        @click="clickItem('img')"
        :class="`edit_${Xindex}_img_${tabIndex}`"
        :src="configs.img"
        :alt="configs.alt"
      />
      <div class="info">
        <div
          class="title"
          @click="clickItem('title')"
          :class="`edit_${Xindex}_title_${tabIndex}`"
          v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
        ></div>
        <div
          class="desc"
          @click="clickItem('desc')"
          :class="`edit_${Xindex}_desc_${tabIndex}`"
          v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
        ></div>
        <div class="xian"></div>
        <div class="icon-address">
          <img
            class="icon"
            @click="clickItem('icon')"
            :class="`edit_${Xindex}_icon_${tabIndex}`"
            :src="configs.icon"
          />
          <div
            class="address"
            @click="clickItem('address')"
            :class="`edit_${Xindex}_address_${tabIndex}`"
            v-html="$util.rex.getHtml(configs[`${nowL}address`] || configs.address)"
          ></div>
        </div>
        <div
          class="address_"
          @click="clickItem('addressDetail')"
          :class="`edit_${Xindex}_addressDetail_${tabIndex}`"
          v-html="$util.rex.getHtml(configs[`${nowL}addressDetail`] || configs.addressDetail)"
        ></div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="logo-desc">
        <img
          class="logo"
          @click="clickItem('logo')"
          :class="`edit_${Xindex}_logo_${tabIndex}`"
          :src="configs.logo"
        />
        <div
          class="logoDesc"
          @click="clickItem('logoDesc')"
          :class="`edit_${Xindex}_logoDesc_${tabIndex}`"
          v-html="$util.rex.getHtml(configs[`${nowL}logoDesc`] || configs.logoDesc)"
        ></div>
      </div>
      <img
        class="manageImage"
        @click="clickItem('manageImage')"
        :class="`edit_${Xindex}_manageImage_${tabIndex}`"
        :src="configs.manageImage"
        :alt="configs.manageImageAlt"
      />
      <div
        class="manageTitle"
        @click="clickItem('manageTitle')"
        :class="`edit_${Xindex}_manageTitle_${tabIndex}`"
        v-html="$util.rex.getHtml(configs[`${nowL}manageTitle`] || configs.manageTitle)"
      ></div>
      <div
        class="manageDesc"
        @click="clickItem('manageDesc')"
        :class="`edit_${Xindex}_manageDesc_${tabIndex}`"
        v-html="$util.rex.getHtml(configs[`${nowL}manageDesc`] || configs.manageDesc)"
      ></div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-company-introduction',
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          icon: '',
          img: '',
          address: '',
          addressDetail: '',
          logo: '',
          logoDesc: '',
          manageImage: '',
          manageTitle: '',
          manageDesc: '',
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
    tabIndex: {
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      isEdit: false,
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e) {
      if (this.isEdit) {
        this.$emit('clickItem', e, this.tabIndex);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.company-introduction-box {
  .top-box {
    display: flex;
    align-items: center;
    background: #fafafa;
    padding: 0 310px 0;
    .img {
      width: 64%;
    }
    .info {
      flex: 1;
      padding-left: 50px;
      .title {
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .desc {
        font-size: 32px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .xian {
        margin: 34px 0 40px 0;
        width: 100%;
        height: 1px;
        background-color: #ccc;
      }
      .icon-address {
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          margin-right: 10px;
        }
        .address {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .address_ {
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 6px;
        padding-left: 30xpx;
      }
    }
  }
  .bottom-box {
    padding: 60px 360px;
    text-align: center;
    .logo-desc {
      display: flex;
      align-items: center;
      width: 100%;
      .logo {
        height: 80px;
      }
      .logoDesc {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-left: 36px;
      }
    }
    .manageImage {
      width: 100%;
      margin-top: 60px;
    }
    .manageTitle {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      padding: 30px 0 14px 0;
    }
    .manageDesc {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-company-introduction-box {
    .top-box {
      padding: 15px;
      .img {
        object-fit: cover;
        width: 45vw;
        height: calc(45vw / 1.35);
      }
      .info {
        .title {
          font-size: 13px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .desc {
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .icon-address {
          .icon {
            width: 7px;
          }
          .address {
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .address_ {
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .bottom-box {
      padding: 15px;
      .logo-desc {
        .logo {
          width: 115px;
          height: auto;
        }
        .logoDesc {
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-left: 12px;
          text-align: left;
        }
      }
      .manageTitle {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .manageDesc {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .bottom-box {
    padding: 15px;
    .logo-desc {
      .logo {
        width: 54px;
        height: auto;
      }
    }
  }
}
</style>
